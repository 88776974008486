import { Translation } from '../translation';

export const en: Translation = {
  'hello.world': 'Hello World',
  'button.tryAgain': 'Try again',
  'navigation.home': 'Home',
  'navigation.zoneEditor': 'Zone Editor',
  'navigation.orgBrowser': 'Org Browser',
  'delete.zones': 'Delete Zones',
  'navigation.eposUpload': 'EPOS Upload',
  'delete.user': 'Delete User',
  'delete.user_other': 'Delete Users',
  'delete.user.confirmDelete': 'Are you sure you want to delete the following users?',
  'delete.user.confirmDelete.singleUser': 'Are you sure you want to delete the following user?',
  'delete.user.singleConfirmDelete': 'Are you sure you want to delete the user:',
  'delete.user.limit': 'Cannot delete more than 10 users at a time.',
  'delete.user.warningAction': 'This action cannot be undone!',
  'delete.store': 'Delete Store',
  'delete.store_other': 'Delete Stores',
  'delete.store.confirmDelete': 'Are you sure you want to delete the following stores?',
  'delete.store.confirmDelete.singleStore': 'Are you sure you want to delete the following store?',
  'force.logout.user.title': 'Do you want to logout the following user?',
  'force.logout.user.warning': 'This will log the user out of all devices',
  'delete.store.limit': 'Cannot delete more than 10 stores at a time',
  'navigation.userManagement': 'User Management',
  'navigation.auditLogs': 'Audit Logs',
  'navigation.tasks': 'Tasks',
  'navigation.storeManagement': 'Store Management',
  'navigation.devicestatus': 'Device Status',
  'uilabel.devicestatus.column.device': 'Device',
  'uilabel.devicestatus.column.org': 'Org',
  'uilabel.devicestatus.column.description': 'Description',
  'uilabel.devicestatus.column.timeSince': 'Last Logs',
  'uilabel.devicestatus.column.exactTime': 'Exact Time',
  'uilabel.devicestatus.column.install': 'install',
  'uilabel.devicestatus.column.uuid': 'Device ID',
  'uilabel.devicestatus.column.deviceData': 'Device Data',
  'uilabel.devicestatus.column.severity': 'Severity',
  'add.stores.button': 'Add Stores To Users',
  'add.stores.warning.text':
    '        The following users will have the selected stores added to them. Please ensure the selection is correct before pressing the "Add Stores" Button.',
  'add.stores': 'Add Stores',
  add_new_zone: 'Add new zone',
  draw_presence_zone: 'Draw new zone',
  'gender.confidence': 'Gender Confidence',
  'zoneEditor.save': 'Save',
  'cmdk.emptyState': 'No results found',
  'uiLabel.orgs': 'Orgs',
  'uiLabel.pages': 'Pages',
  'uiLabel.delete': 'Delete',
  'form.label.email': 'Email Address',
  'form.label.password': 'Password',
  'installer.notes': 'Installer Notes',
  'device.health.title': 'Device Health',
  'device.health.healthy': 'This device is healthy, no action needed',
  'device.health.unhealthy': 'This device is unhealthy',
  'device.health.very.unhealthy': 'This device is very unhealthy',
  'device.health.recent.scores': 'View Recent Scores',
  'device.health.tooltip':
    "Device Health Score: A quick snapshot of your device's well-being, rated from 0 to 100. Use it as a general guide to assess overall health. Keep an eye on the health logs; any irregularities might signal a problem, and it's best to reach out to our tech team. For a closer look, hit the 'Recent Scores' button to view scores from the last 30 minutes, complete with explanations on how they were calculated.",
  'device.health.loading': 'Getting Device Health...',
  avg_fps: 'Average FPS (>10): ',
  min_fps: 'Minimum FPS (>10): ',
  bad_camera_reads: 'Bad Camera Reads (0): ',
  bad_logs_count: 'Bad Logs Count (<5): ',
  high_temp_messages: 'High Temp Messages (0): ',
  live_person_count: 'Live Person Count: ',
  process_failed_count: 'Process Failed Count (0): ',
  'wifi.details.ssid': 'Wifi SSID:',
  'wifi.details.psk': 'Wifi PSK:',
  'wifi.details.updated': 'Wifi Details are up-to-date',
  'wifi.details.not.updated': 'Wifi details currently updating...',
  'wifi.details.title': 'Update Wifi Detils',
  'wifi.details.info':
    'Used to update wifi details of a device. Device pings server every ~5 mins to see if any maintenance tasks need to be run. If wifi details are not updated in this time, contact tech team.',
  'form.placeholder.email': 'Enter your email address',
  'form.placeholder.password': 'Enter your password',
  'form.validation.email.required': 'Please enter your email address',
  'form.validation.password.required': 'Please enter your password',
  'gender.body.conf': 'Gender Body Confidence',
  'min.female.confidence': 'Female Confidence',
  'min.male.confidence': 'Male Confidence',
  'age.confidence': 'Age Confidence',
  'age.bleed.rate': 'Inverse Age Bleed Rate',
  'age.bleed.rate.age.explainer':
    'The distance the age will be pushed away from the middle age. Note this is inverted, so if the low this value the greater the push from the middle age and vice versa.',
  'age.bleed.middle.age.explainer':
    'The models on the devices have a tendency to trend around certain ages. This value is the middle point from which we shift ages away from. For example if we have this as 25 and we get the following ages, 24 and 26, we will shift 24 to 23 and 26 to 27.',
  'age.bleed.middle.age': 'Inverse Age Bleed Middle Age',
  'store.code': 'Store Code',
  'store.code.no.code.applied': 'No Store Code Applied',
  'store.code.info.tooltip':
    'The store_code field serves as a key to map EPOS data from CSV files to specific devices. When the store_code value in the model matches the corresponding value in the EPOS data, the mapping process is triggered, linking the data to the device.',
  'uiLabel.errorBoundaryDescription': 'Something went wrong. Please try again.',
  'uiLabel.userManagement.openingHours': 'e.g. 09:00',
  'uiLabel.userManagement.closingHours': 'e.g. 17:00',
  'view.config': 'View Config',
  rollback: 'Rollback',
  preview: 'Preview',
  'hide.preview': 'Hide Preview',
  'uiLabel.versionPreview': 'Version Preview',
  'initial.version': 'Initial Version',
  'uiLabel.systems': 'Systems',
  'uiLabel.toggleTheme': 'Toggle Theme',
  'uiLabel.threshold': 'Threshold',
  'uiLabel.presenceZoneName': 'Presence Zone Name',
  'uiLabel.presenceZoneName.placeholder': 'Enter a name for the presence zone',
  'uiLabel.detection.zone': 'Detection Zone',
  'uiLabel.presence.zone': 'Presence Zone',
  'uiLabel.zoneTypes': 'Zone Types',
  'uiLabel.detectionType': 'Detection Type',
  'uiLabel.inDirection': 'Describe which direction is "In"',
  'uiLabel.directionAngle': 'Direction Angle',
  'uiLabel.eposFilterFields': 'EPOS Filtering',
  'uiLabel.configurations': 'Configurations',
  'uiLabel.notes': 'Notes',
  'uiLabel.or': 'or',
  'uiLabel.zoneEditor.configuration.emptyState': 'Select a zone to edit/view configuration',
  'uiLabel.minTime': 'Minimum Time',
  'uiLabel.area': 'Area',
  'uiLabel.boundary': 'Boundary',
  'uiLabel.layers': 'Layers',
  'uiLabel.recently.viewed.devices': 'Recently Viewed Devices',
  'uiLabel.recently.viewed.orgs': 'Recently Viewed Orgs',
  'placeholder.cmdk.search': 'Search pages, orgs or devices',
  'search.users.placeholder': 'Search username, email, id or org',
  'uiLabel.previous': 'Previous',
  'uiLabel.new': 'New',
  'select.device': 'Select device',
  'select.device_other': 'Select devices',
  'stat.orgs': 'Orgs',
  'stat.devices': 'Devices',
  'stat.pre.init': 'Pre-init Device',
  'stat.pre.init_other': 'Pre-init Devices',
  'stat.task': 'Task',
  'stat.task_other': 'Tasks',
  'stat.users': 'Users',
  'edit.zones': 'Edit Zones',
  'update.device.details': 'Update Device Details',
  'uiLabel.clone.zone': 'Clone Zone',
  'zoneEditor.notes.header': 'Updated by {{user}}, {{date}}.',
  'zoneEditor.version.meta.header': 'Added by {{user}}, {{date}}.',
  'toggle.visibility': 'Toggle Visibility',
  'uiLabel.authenticating': 'Authenticating...',
  'uiLabel.authenticating.description': 'Please wait while we authenticate you.',
  'uiLabel.unAuthenticated': 'Unauthenticated',
  'uiLabel.unAuthenticated.redirecting': 'Redirecting in {{seconds}}',
  'developer.links': 'Developer Links',
  'developer.links.splunk': 'Splunk Data',
  'developer.links.device.config': 'Device Config',
  'developer.links.greengrass': 'GreenGrass',
  'developer.links.device_data': 'Device Data',
  'view.org': 'View Org',
  'uiLabel.versionControl': 'Version History',
  'currently.selected.device': 'Currently Selected Device',
  'currently.selected.device_other': 'Currently Selected Devices',
  'currently.selected.device.empty.state': "You haven't selected any device, click on a device to add.",
  'add.store.dialog.title': 'Add Devices to {{store_name}} Store',
  'add.device': 'Add Device',
  'add.device_other': 'Add Devices',
  'uiLabel.signIn': 'Sign In',
  'uiLabel.exclusion.zone': 'Exclusion Zone',

  // Task List Filter Labels
  'uiLabel.tasklist.alltasks': 'All Tasks',
  'uiLabel.tasklist.video': 'Video',
  'uiLabel.tasklist.copy': 'Copy',
  'uiLabel.tasklist.move': 'Move',
  'uiLabel.tasklist.timeshift': 'Timeshift',
  'uiLabel.tasklist.reingest': 'Reingest',
  'uiLabel.tasklist.deleteandreingest': 'Delete and Reingest',
  'tasklist.notasks': 'No recent tasks for',
  // Create Task Titles and Descriptions
  'uiLabel.createTask.title': 'Create Task',
  'uiLabel.createTask.generateVideo.title': 'Generate Video',
  'uiLabel.createTask.copy.title': 'Copy Data to this device',
  'uiLabel.createTask.move.title': 'Move',
  'uiLabel.createTask.timeshift.title': 'Timeshift',
  'uiLabel.createTask.reingest.title': 'Reingest',
  'uiLabel.createTask.deleteAndReingest.title': 'Delete & Reingest',
  'uiLabel.createTask.delete.title': 'Delete',
  'uiLabel.delete.submit.success': 'Delete successfull',
  'uilabel.delete.submit.failed': 'Delete failed',
  'uiLabel.createTask.generateVideo.description':
    'Generate a bbox video of a device. Once the video is generated, an email with a link to download the video file will be sent to your email.',
  'uiLabel.createTask.copy.description': 'Copy data from another device to the currently selected device.',
  'uiLabel.createTask.move.description': 'Move data from device 1 to device 2.',
  'uiLabel.createTask.timeshift.description':
    "Shift data forward/backward by a clickhouse time duration e.g. +6h. This doesn't delete any data from Splunk. Note that the output link in the popup notification is incorrect for this option.",
  'uiLabel.createTask.reingest.description': 'Fetch raw data from s3 and reprocess it',
  'uiLabel.createTask.deleteAndReingest.description':
    'Delete data from clickhouse and then fetch the raw data from s3 and reprocess it.',
  'uiLabel.createTask.delete.description': 'Delete data from clickhouse.',
  'uiLabel.timeshift.modify.tooltip':
    'Modify the time shift by a certain %, so if there is 100 people in a window and you have +5, there will now be 105 people, same if you reduce. Min: -50 Max +50',
  'uiLabel.timeshift.time_offset.tooltip':
    'Offset to apply to the window. Format should be (+/-)(num)(time_amount) so +6H copys the current window 6 hours after that window. Valid time amounts are d=Day, h=hour, m=minute, s=second',
  'uilabel.timeshift.submit.failed': 'Failed to timeshift data, contact support',
  'uilabel.timeshift.submit.success': 'Timeshift submitted',
  'uiLabel.timeshift.modify.placeholder': 'enter a number between -50 and +50',
  // TASKS
  'uiLabel.tasks.toggleButton': 'View All Users',
  'tasks.description.createdBy': 'Created By: ',
  'tasks.description.everyone': 'Everyone',

  'uiLabel.video.blurVideo': 'Blur Video?',
  // FEATURE DESCRIPTIONS
  'featureList.zoneEditor.description':
    'Create, Read, Update or Delete Detection, Presence and Exclusion Zones on a device.',
  'featureList.orgBrowser.description':
    'Browse devices are assigned to a customer, and get an overview of their health.',
  'featureList.userManagement.description': 'Manage users and their permissions.',
  'featureList.auditLogs.description': 'View audit logs for all actions performed by users.',
  'featureList.tasks.description': 'View tasks and their status. Tasks include; video generation, re-ingestions etc.',
  'featureList.deviceManagement.description': 'Manage devices status and Installs',
  'featureList.storeManagement.description': 'Manage stores and their configurations.',
  'search.storeManagement.placeholder': 'Search store name, id or device id',
  'featureList.eposUpload.description': 'Upload EPOS data to a device.',
  'featureList.devicestatus.description':
    'View Devices that are currently down (not sending any logs) and navigate quickly to details of device to identify issues.',
  'featureList.retry.user.report.description': 'Retry Failed User Reports',
  'featureList.zoneSandbox.description': 'Demo Detection, Presence and Exclusion Zones on a device.',
  // ZONE EDITOR -> Indirection selector
  'uiLabel.inDirection.downToUp.title': 'Describe which direction is "In"',
  'uiLabel.inDirection.upToDown': 'Up to Down',
  'uiLabel.inDirection.downToUp': 'Down to Up',
  'uiLabel.inDirection.leftToRight': 'Left to Right',
  'uiLabel.inDirection.rightToLeft': 'Right to Left',

  'navigation.retry.user.report': 'Retry User Report',

  // ZONE EDITOR -> Tooltips
  'uiLabel.tooltips.detectionType': 'Select between Area and Boundary',
  'uiLabel.tooltips.inDirection': 'Select the direction that is "In"',
  'uiLabel.tooltips.directionAngle': 'Direction angle in degrees',
  'uiLabel.zoneEditor.drawing.tooltip': 'Double click canvas to close polygon',
  'uiLabel.tooltips.eposFilters':
    'Epos Filter Field is the field that we want to look at. e.g. product_category. The Epos Filter is the specific product we want to look at. e.g. Jeans',
  'uiLabel.tooltips.category':
    'A category used in presence zones to denote what kind of zone it is. Depending on the category, different components will appear in the dashboard. e.g a zone with Queue or Till will show a time to serve component.',

  // ORG_BROWSER
  'uiLabel.allDevices': 'All Devices ({{ totalDevicesCount }})',
  'uiLabel.activeDevices': 'Active Devices ({{ totalActiveDevicesCount }})',
  'uiLabel.inactiveDevices': 'Inactive Devices ({{ totalInactiveDevicesCount }})',
  'uiLabel.preInitDevices': 'Pre-init Devices ({{ totalPreInitDevicesCount }})',
  'uiLabel.device': 'Device',
  'uiLabel.device_other': 'Devices',
  'placeholder.search.orgBrowser': 'Search orgs, device name or id..',
  'uiLabel.welcome.back': 'Welcome back, {{name}}',

  //confirmation dialog
  'uiLabel.confirmationDialog.title': 'Are you sure?',
  'uiLabel.confirmationDialog.confirm': 'Confirm',
  'uiLabel.confirmationDialog.cancel': 'Cancel',
  'uiLabel.confirmationDialog.confirm.save': 'Yes, save',
  'uiLabel.confirmationDialog.confirm.continue': 'Yes, continue',

  'uiLabel.confirmationDialog.confirm.delete.all.zone': 'Yes, delete all',
  'confirmationDialog.delete.all.zone.description':
    'Are you sure you want to delete all {{zone_type}} zones on {{device_name}}?',
  'confirmationDialog.delete.all.zone.title': 'Delete all {{zone_type}} zones?',

  'confirmationDialog.save.new.zone.description':
    'You are about to save a new zone. Please confirm that you have validated all configurations.',
  'confirmationDialog.save.new.zone.description_other':
    'You are about to save {{count}} new zones. Please confirm that you have validated all configurations.',
  'confirmationDialog.save.new.zone.title': 'Save {{count}} new zone',
  'confirmationDialog.save.new.zone.title_other': 'Save {{count}} new zones',
  'confirmationDialog.delete.zone.description': 'Are you sure you want to delete the selected zone?',
  'confirmationDialog.delete.zone.title': 'Delete zone',
  'confirmationDialog.presence.zone.title': 'Presence zone',
  'confirmationDialog.zone.description':
    'You are about to switch to a different zone type with unsaved changes. Are you sure you want to continue?',
  'saved.exclusion.zone': 'Exclusion zone has been saved.',
  'confirmationDialog.exclusion.zone.title': 'Exclusion zone',
  'confirmationDialog.confirm.rollback': 'Rollback',
  'confirmationDialog.rollback.description': 'Are you sure you want to rollback to {{version}} by {{author}} ?',
  'confirmationDialog.rollback.title': 'Rollback Version?',

  'confirmationDialog.detection.zone.title': 'Detection zone',
  'confirmationDialog.go.back.title': 'Unsaved changes',
  'confirmationDialog.go.back.description': 'You have unsaved changes. Are you sure you want to go back?',
  'confirmationDialog.clone.zone.title': 'Clone zone',
  'confirmationDialog.clone.zone.description':
    'You are about to clone the selected zone. Are you sure you want to continue ?',

  // notifications
  'saved.detection.zone': 'Detection zone has been saved.',
  'saved.presence.zone': 'Presence zone has been saved.',
  'warn.edit.previous.zone': 'Cannot edit previous zone, Clone the zone to edit.',
  'error.max.new.shape.limit': 'Cannot add more than 10 shapes',
  'video.create.success': 'Video creation has been initiated.',
  'video.create.failed': 'Video creation failed.',
  'delete.reingest.failed': 'Delete and reingest failed',
  'delete.reingest.success': 'Delete and reingest has been initiated',
  'copy.failed': 'Copy has failed',
  'copy.success': 'Copy task has been initiated',
  // validation
  'empty.presence.zone.name.on.save': 'Invalid configuration; Zone {{id}} does not have a name.',
  'empty.state.pre.init.devices': 'No pre-init devices found.',
  'empty.state.recently.viewed.devices': 'No recently viewed devices found. You must be new here.',
  'empty.state.recently.viewed.org': 'No recently viewed orgs found. You must be new here.',

  'wa.navigation.dashboard': 'Dashboard',
  'wa.navigation.inStoreAnalysis': 'In-Store Analysis',
  'wa.navigation.reports': 'Reports',
  'wa.navigation.productAnalysis': 'Product Analysis',
  'wa.navigation.profileAnalysis': 'Profile Analysis',

  'epos.input.unmapped_store_code': 'Unmapped Store Code, verify the store code in the EPOS data is mapped to a device',

  'device.details': 'Device Details',

  // Retry User Report
  'retry.dialog.message': 'Are you sure you want to retry this user report?{{emails}}',
  'retry.dialog.message_other': 'Are you sure you want to retry these user report?{{emails}}',

  'uiLabel.maintenance.mode': 'Maintenance Mode',
  'uiLabel.maintenance.mode.warning.enable': 'Are you sure you want to enable maintenance mode?',
  'uiLabel.maintenance.mode.warning.disable': 'Are you sure you want to disable maintenance mode?',

  'tracking.confidence': 'Tracking Confidence',

  'navigation.reports': 'Reports',
  'navigation.zoneSandbox': 'Zone Sandbox',
  'navigation.deviceManagement': 'Device Management',
};
